import Nav from "./IndexPublic/Nav"
import NoList from "@/components/common/NoList";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
const loading={
    install:function(Vue){
        // Vue.component()是用来注册全局组件的
        Vue.component("Nav",Nav)
        Vue.component("NoList",NoList)
        Vue.component("el-image-viewer",ElImageViewer)
    }  // Header'这就是后面可以使用的组件的名字，install是vue实例上默认的一个方法
}
export default loading
