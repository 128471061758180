<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import getMenu from "@/utils/getMenu";
export default {
  name: 'App',
  data() {
    return{

    }
  },
  created() {
    //
    getMenu(this.$store.state.menu);

    // 角色
    this.$store.dispatch("setRole");
    // 科室
    this.$store.dispatch("setDept");
    // 产品类型
    this.$store.dispatch("setCategory");
    // 使用年龄
    this.$store.dispatch("setFitAge");
    // 产品特性
    this.$store.dispatch("setProductFeature");
    // 报告类型
    this.$store.dispatch("setReportType");
    // 医嘱
    this.$store.dispatch("setUnit");
    // 预约随访时段
    this.$store.dispatch("setFollowAppoint");
    // 未接听状态
    this.$store.dispatch("setAnswerStatus");
    // 随访医院
    this.$store.dispatch("setFollowHospital");
    // 随访科室
    this.$store.dispatch("setFollowDept");
    // 项目类别
    this.$store.dispatch("setItemType");
    // 预约服务
    this.$store.dispatch("setService");
    // 适用性别
    this.$store.dispatch("setFitGender");
    // 附加项类型
    this.$store.dispatch("setItemPlusType");
    // 系统设置医院
    this.$store.dispatch("setHospital");
    // 退款状态
    this.$store.dispatch("setRefundState");
    // 订单状态
    this.$store.dispatch("setOrderState");
  },
  mounted() {

  },
}
</script>

<style>

</style>
