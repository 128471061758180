<template>
  <div class="has-logo">
    <Logo :isCollapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        router
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebaritem
          v-for="route in nav_list"
          :key="route.id"
          :item="route"
          :base-path="route.menuUrl"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import Logo from '@/components/common/IndexPublic/Logo'
import sidebaritem from './SidebarItem'
import variables from '@/style/common/variables.scss'
export default {
  name: 'Nav',
  data() {
    let pUrl
    try {
      pUrl = this.$route.meta.pUrl ? '/' + this.$route.meta.pUrl : null
    } catch (error) {
      pUrl = null
    }
    return {
      activeMenu: pUrl || this.$route.path,
      variables,
      nav_list: this.$store.state.menu,
    }
  },
  watch: {
    $route(newValue) {
      let pUrl
      try {
        pUrl = newValue.meta.pUrl ? '/' + newValue.meta.pUrl : null
      } catch (error) {
        pUrl = null
      }
      this.activeMenu = pUrl || newValue.path
    },
  },
  created() {},
  mounted() {},
  methods: {},
  props: ['isCollapse'],
  components: {
    Logo,
    sidebaritem,
  },
}
</script>

<style lang="scss" scoped>
</style>
