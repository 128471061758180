<template>
  <div class="noList">
    <img src="../../assets/Nolist/noList.png" alt="">
    <slot name="text">

    </slot>
  </div>
</template>

<script>
export default {
name: "NoList"
}
</script>

<style lang="less" scoped>
.noList{
  width: 100%;
  //height: 100%;
  padding-top: 1.0625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    width: 30%;
    display: block;
  }
  p{
    margin-top: 1.25rem;
    color: #808080;
  }
}
</style>
