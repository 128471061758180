import Vue from 'vue'
import App from './App.vue'
import router from "@/router/router";
import store from "@/store/store";
import ElementUI from 'element-ui';
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import 'element-ui/lib/theme-chalk/index.css';
// common.scss
import "@/style/common/common.scss";

// public
import publicComponents from "@/components/common/public";
// api
import { post, get, put, del , File } from "@/api/http";
// 视频播放器
import VueCoreVideoPlayer from 'vue-core-video-player'

// 年龄
Vue.prototype.GetAge = function (identityCard) {
  var len = (identityCard + "").length;
  if (len == 0) {
    return 0;
  } else {
    if ((len != 15) && (len != 18))//身份证号码只能为15位或18位其它不合法
    {
      return 0;
    }
  }
  var strBirthday = "";
  if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
  {
    strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
  }
  if (len == 15) {
    strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
  }
  //时间字符串里，必须是“/”
  var birthDate = new Date(strBirthday);
  var nowDateTime = new Date();
  var age = nowDateTime.getFullYear() - birthDate.getFullYear();
  //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// 数组上移下移
Vue.prototype.changeSort = function (arr,index, type) {
  arr.splice(type ? index : index - 1, 1, ...arr.splice(type ? index + 1 : index, 1, arr[type ? index : index - 1]));
};

Vue.prototype.swapArray = function (arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
};

// 数组置顶
Vue.prototype.arr_top = function (arr,index1) {
  arr.unshift(arr[index1]);
  arr.splice(index1+1,1);
  return arr;
}

// 拖动排序
Vue.prototype.drag = function(arr,index,tindex){
  //如果当前元素在拖动目标位置的下方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置的地方新增一个和当前元素值一样的元素，
  //我们再把数组之前的那个拖动的元素删除掉，所以要len+1
  if(index>tindex){
    arr.splice(tindex,0,arr[index]);
    arr.splice(index+1,1)
  }
  else{
    //如果当前元素在拖动目标位置的上方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置+1的地方新增一个和当前元素值一样的元素，
    //这时，数组len不变，我们再把数组之前的那个拖动的元素删除掉，下标还是index
    arr.splice(tindex+1,0,arr[index]);
    arr.splice(index,1)
  }
}

// 按钮权限
// Vue.directive('Btnermission', {
//   inserted: function (el,binding) {
//     // console.log(el)
//     // console.log(binding)
//     let btnPermission = JSON.parse(sessionStorage.getItem('auth'))
//
//     if(!btnPermission.includes(binding.value)){
//       el.parentNode.removeChild(el)
//     }
//   }
// })

// api
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$del = del;
Vue.prototype.$file = File;
Vue.prototype.$driver = new Driver({
  allowClose: false, //禁止点击外部关闭
  doneBtnText: '完成', // 完成按钮标题
  closeBtnText: '关闭', // 关闭按钮标题
  stageBackground: '#fff', // 引导对话的背景色
  nextBtnText: '下一步', // 下一步按钮标题
  prevBtnText: '上一步', // 上一步按钮标题
})
// ElementUI
Vue.use(ElementUI);
// public 组件
Vue.use(publicComponents);
// 视频播放器
Vue.use(VueCoreVideoPlayer,{
  lang: 'zh-CN'
});

import Video from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-markers'
Vue.prototype.$video = Video

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
