import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store/store";
Vue.use(VueRouter)

// 重复点击导航时（连续点击相同路由链接）
// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
// replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}

const createRouter = () =>  new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/",
            component: () => import("@/view/Login"),
            meta: {
                title: "登录"
            }
        },
    ]
})

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to,form,next) => {
    if(to.path === '/') {
        // store.commit("setMenu",[]);
        // store.commit("setToken",null);
        next();
    }else {
        if(store.state.token) {
            next();
        }else {
            next("/");
        }
    }

    document.title= to.meta.title;
})


export default router
