import axios from 'axios';
// import Qs from 'qs'
import router from '../router/router';
import store from "@/store/store";
import { Message } from 'element-ui';


axios.defaults.baseURL = process.env.VUE_APP_APIURL
axios.defaults.timeout = 60000

axios.interceptors.request.use(
    config => {
        if (!config.headers['Content-Type']) {
            config.headers = {
                "Content-Type": "application/json;charset=utf-8;"
            }
        }

        const token = store.state.token;
        if (token) {
          config.headers.authorization = token;
        }

        return config
    },
    error => {
        // 处理请求错误
        return Promise.reject(error)
    }
)
axios.interceptors.response.use(
    res => {
        // console.log(res.data);
        // console.log(res);
        if(res.data.code) {

            if(res.data.code == "SUCCESS") {
                // 获取token
                if(res.headers.authorization) {
                    store.commit("setToken",res.headers.authorization)
                }

                return res.data
            }else {
                Message.error(res.data.message);
            }

        }else {
            return res;
        }



    },
    error => {
        if (error.code === 'ECONNABORTED') {
            console.log('连接超市，网络不加')
        } else if (error.response.status == 400) {
            Message.error(error.response.data.message);
        } else if (error.response.status == 401) {
            router.replace({
                path: "/"
            })
            Message.error(error.response.data.message);
        }  else if (error.response.status == 403) {
            router.replace({
                path: "/"
            })
            Message.error(error.response.data.message);
        } else if (error.response.status == 500) {
            if(error.response.data.message) {
                Message.error(error.response.data.message);
            }else {
                Message.error("系统异常");
            }
        }
        return Promise.reject(error);
    }
)

export function get(url, params, falg) {
    return new Promise((resolve, reject) => {
        axios.get(falg ? url + "/" + params : url, falg ? {} : {
            params: params,
        })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function File(url, params) {
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: "get",
            params,
            responseType: 'blob'
        })
            .then(res => {
                const fileName = res.headers["content-disposition"].split("''")[1]
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
    })
}

export function put(url, data) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
    })
}

export function del(url, params, falg) {
    return new Promise((resolve, reject) => {
        axios.delete(falg ? url + "/" + params : url, falg ? {} : {
            params: params
        })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
