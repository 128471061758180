<template>
  <div class="sidebar-logo-container" :class="{'collapse':isCollapse}">
    <transition name="sidebarLogoFade">
      <div v-if="isCollapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 v-else class="sidebar-title">{{ title }} </h1>
      </div>
      <div v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 class="sidebar-title">{{ title }} </h1>
      </div>
    </transition>
  </div>
</template>

<script>
import logo from "../../../assets/index/logo.png";

export default {
  name: "Logo",
  data() {
    return{
      logo: logo,
      title: '御锦健康',
    }
  },
  props: {
    isCollapse: {
      type: Boolean,
      // request: !this.$store.state.open
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../style/common/variables";
.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: $LogoBackground;
  padding-left: 20px;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    padding: 0;
    text-align: center;
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}

</style>
