import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// eslint-disable-next-line no-unused-vars
import { get,post } from "../api/http";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        // 侧边栏
        open: true,
        // 用户信息
        user_info: null,
        // 角色
        role: null,
        // 科室
        dept: null,
        // 产品类别
        category: [],
        // 使用年龄
        fitAge: [],
        // 产品特性
        productFeature: [],
        // 报告类型
        reportType: [],
        // 医嘱单位
        unit: [],
        // 预约随访时段
        follow_appoint: [],
        // 未接听状态
        answerStatus: [],
        // 随访科室
        followDept: [],
        // 随访医院
        followHospital: [],
        // 导航权限
        menu: [],
        // 项目类型
        itemType: [],
        // 预约服务
        service: [],
        // 适用性别
        fitGender: [],
        // 附加项类型
        itemPlusType: [],
        // 系统设置医院接口
        hospital: [],
        // 退款状态
        refundState: [],
        // 订单状态
        orderState: [],
        // 教育程度 - 试剂盒
        educationLevelList: [],
        // 婚姻状况 - 试剂盒
        maritalStatusList: [],
        // 医保类型 - 试剂盒
        medicalInsuranceTypeList: [],
        // 职业 - 试剂盒
        occupation: []
    },
    mutations: {
        setOccupation(state,data) {
            state.occupation= data;
        },
        setEducationLevelList(state,data) {
            state.educationLevelList= data;
        },
        setMaritalStatusList(state,data) {
            state.maritalStatusList= data;
        },
        setMedicalInsuranceTypeList(state,data) {
            state.medicalInsuranceTypeList= data;
        },
        setOrderState(state,data) {
            state.orderState= data;
        },
        setRefundState(state,data) {
            state.refundState= data;
        },
        setOpen(state,data) {
            state.open= data;
        },
        setHospital(state,data) {
            state.hospital= data;
        },
        setItemPlusType(state,data) {
            state.itemPlusType= data;
        },
        setFitGender(state,data) {
            state.fitGender= data;
        },
        setService(state,data) {
            state.service= data;
        },
        setItemType(state,data) {
            state.itemType= data;
        },
        setMenu(state,data) {
            state.menu= data;
        },
        setFollowHospital(state,data) {
            state.followHospital= data;
        },
        setFollowDept(state,data) {
            state.followDept= data;
        },
        setAnswerStatus(state,data) {
            state.answerStatus= data;
        },
        setFollowAppoint(state,data) {
            state.follow_appoint= data;
        },
        setUnit(state,data) {
            state.unit= data;
        },
        setReportType(state,data) {
            state.reportType= data;
        },
        setProductFeature(state,data) {
            state.productFeature= data;
        },
        setFitAge(state,data) {
            state.fitAge= data
        },
        setCategory(state,data) {
            state.category= data;
        },
        setRole(state,data) {
            state.role= data;
        },
        setDept(state,data) {
            state.dept= data;
        },
        setUserInfo(state,data) {
            state.user_info= data;
        },
        setToken(state,data) {
            state.token= data;
        }
    },
    actions: {
        setOccupation(context,data) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/reagent/occupation",{type: data})
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setOccupation",res);
                })
        },
        setEducationLevelList(context,data) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/reagent/education",{type: data})
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setEducationLevelList",res);
                })
        },
        setMaritalStatusList(context,data) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/reagent/marital",{type: data})
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setMaritalStatusList",res);
                })
        },
        setMedicalInsuranceTypeList(context,data) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/reagent/insuranceType",{type: data})
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setMedicalInsuranceTypeList",res);
                })
        },
        setOrderState(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/order/status")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setOrderState",res);
                })
        },
        setRefundState(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/refund/status")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setRefundState",res);
                })
        },
        setHospital(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/hospital")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setHospital",res);
                })
        },
        setItemPlusType(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/itemPlusType")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setItemPlusType",res);
                })
        },
        setFitGender(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/fitGender")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setFitGender",res);
                })
        },
        setService(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/appointment/service")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setService",res);
                })
        },
        setItemType(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/itemType")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setItemType",res);
                })
        },
        setFollowHospital(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/appointHospital")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setFollowHospital",res);
                })
        },
        setFollowDept(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/appointDept")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setFollowDept",res);
                })
        },
        setAnswerStatus(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/answer-status")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setAnswerStatus",res);
                })
        },
        setFollowAppoint(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/follow-appoint")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setFollowAppoint",res);
                })
        },
        setUnit(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/consultationUnit")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setUnit",res);
                })
        },
        setReportType(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/report-type")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setReportType",res);
                })
        },
        setProductFeature(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/productFeature")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setProductFeature",res);
                })
        },
        setFitAge(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/fitAge")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setFitAge",res);
                })
        },
        setCategory(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/category/enum")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setCategory",res);
                })
        },
        setRole(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/role-type")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setRole",res);
                })
        },
        setDept(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve,reject) => {
                get("base/dict/dept-type")
                    .then(res=> {
                        resolve(res.data)
                    })
            })
                .then(res => {
                    context.commit("setDept",res);
                })
        }
    },
    modules: {},
    plugins: [createPersistedState({
        key: "Manager",
        storage: window.sessionStorage
    })],
})
